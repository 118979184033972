<template>
<div class="d-flex h-100 text-center text-white main-bg">
  <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <header class="mb-auto main">
      <div>
        <!-- <h3 class=""> -->
        <a href="https://futurefitbusiness.org">
          <img width="140" class="float-md-start mb-0" src="@/assets/FF-Business-White.png" />
        </a>

        <!-- </h3> -->
        <nav class="nav nav-masthead justify-content-center float-md-end">
          <a :class="{'active': page === 'home'}" @click="page='home'" class="nav-link ">Home</a>
          <a href="https://profiler.futurefit.app" class="nav-link ">{{riskAppName}}</a>
          <!-- <a :class="{'active': page === 'risk'}" @click="page='risk'" class="nav-link ">{{riskAppName}}</a> -->
          <a href="https://workshop.futurefit.app" class="nav-link ">Workshop</a>
          <!-- <a :class="{'active': page === 'workshop'}" @click="page='workshop'" href="https://workshop.futurefit.app" class="nav-link ">Workshop</a> -->
          <a :class="{'active': page === 'contact'}" @click="page='contact'" class="nav-link ">Contact</a>
        </nav>
      </div>
    </header>

    <div class="bg-svg-1">
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path fill="#5AC3E3"
          d="M40.1,-65.7C52.3,-54.7,62.5,-43.9,66.2,-31.5C69.8,-19.1,66.9,-4.9,63.8,8.4C60.7,21.8,57.5,34.4,49.4,41.7C41.4,49,28.5,51,17.6,49.1C6.8,47.3,-1.9,41.6,-15.2,42.1C-28.6,42.6,-46.6,49.3,-55.1,44.8C-63.5,40.3,-62.4,24.5,-62.6,10.2C-62.8,-4.2,-64.5,-17.2,-58.4,-25C-52.3,-32.8,-38.4,-35.5,-27.3,-47C-16.3,-58.6,-8.2,-79.1,2.9,-83.7C14,-88.2,28,-76.8,40.1,-65.7Z"
          transform="translate(100 100)" />
      </svg>

    </div>
    <!-- <div class="bg-svg-2">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#0099ff" fill-opacity="1"
          d="M0,128L48,133.3C96,139,192,149,288,170.7C384,192,480,224,576,224C672,224,768,192,864,197.3C960,203,1056,245,1152,250.7C1248,256,1344,224,1392,208L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
        </path>
      </svg>
    </div> -->


    <div v-show="page === 'home'" class="px-3 main">
      <h1>Future-Fit online tools.</h1>
      <p class="lead">Welcome to the growing collection of online tools, you can jump to the <a href="https://profiler.futurefit.app" class="mega-link">{{riskAppName}}</a> or the <a href="https://workshop.futurefit.app" class="mega-link">SDG
          Workshop</a>
      </p>
      <p class="lead">
        If you've stumbled upon this page and would like
        to
        know more, <span @click="page='contact'" class="mega-link">Contact us</span>.</p>
      <!-- <p class/="lead"></p> -->
      <!-- <p class="lead">
        <a href="#" class="btn btn-lg btn-secondary fw-bold border-white bg-white">Learn more</a>
      </p>
      <p class="lead">
        <a href="#" class="btn btn-lg btn-outline-secondary fw-bold ">Learn more</a>
      </p> -->
    </div>

    <div v-show="page === 'contact'" class="px-3 main">
      <contact></contact>
    </div>

    <footer class="mt-auto text-white-50 main">
      <p>Created by <a href="https://futurefitbusiness.org" class="text-white">Future-Fit</a>.</p>
    </footer>
  </div>

</div>
</template>

<script>
import Contact from '@/components/Contact'

export default {
  name: 'Landing',
  data: function() {
    return {
      page: 'home',
      showSidebar: false,
      riskAppName: 'Risk Profiler'
    }
  },
  components: {
    Contact,
  }
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none;
  /* Prevent inheritance from `body` */
}

.bg-svg-1 {
  position: fixed;
  z-index: 0;
  width: 3200px;
  z-index: 10;
  left: -800px;
  top: -2177px;
  opacity: 0.3;
}

.bg-svg-2 {
  position: fixed;
  z-index: 0;
  /* width: 3200px; */
  z-index: 11;
  left: 0px;
  top: 0px;
  /* opacity: 0.3; */
}

.main {
  z-index: 12;
}

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.cover-container {
  max-width: 42em;
}

.nav-link {
  cursor: pointer;
}

.main-bg {
  background: #4BA4C0;
}

.mega-link {
  font-size: 30px;
  line-height: 64px;
  color: #1d3e62;
  font-weight: bold;
  border-bottom: 7px solid #3d7d9f;
  text-anchor: unset;
  cursor: pointer;
  text-decoration: none !important;
}

.mega-link:hover {
  color: white;
  border-bottom: 10px solid white;
}

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link+.nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}
</style>
