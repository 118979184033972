<template>
<div class="">
  <form class="row g-3">
    <div class="col-md-6">
      <label for="inputPassword4" class="form-label">Name</label>
      <input type="text" class="form-control" id="inputPassword4">
    </div>
    <div class="col-md-6">
      <label for="inputEmail4" class="form-label">Email*</label>
      <input type="email" class="form-control" id="inputEmail4" required>
    </div>
    <div class="col-12">
      <textarea class="form-control" rows="4" aria-label="With textarea" required></textarea>
    </div>

    <div class="col-12">
      <button type="submit" class="btn btn-light fw-bold border-white bg-white">Send</button>
    </div>
  </form>

</div>
</template>

<script>
export default {
  name: 'Contact',
  data: function() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
